import { default as _91uuid_93ZrDXI0eeOCMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/[uuid].vue?macro=true";
import { default as otpB7jAz4ETvCMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/otp.vue?macro=true";
import { default as requestLIRS0QE8NqMeta } from "/vercel/path0/apps/main/pages/auth/email/verify/request.vue?macro=true";
import { default as _91uuid_93jRin05NlcQMeta } from "/vercel/path0/apps/main/pages/auth/invite/[uuid].vue?macro=true";
import { default as indexmH2SdOBKhjMeta } from "/vercel/path0/apps/main/pages/auth/password/reset/index.vue?macro=true";
import { default as _91uuid_93qNnNEEpYZTMeta } from "/vercel/path0/apps/main/pages/auth/password/reset/submit/[uuid].vue?macro=true";
import { default as changeemailA1f58kT6GZMeta } from "/vercel/path0/apps/main/pages/dashboard/account/changeemail.vue?macro=true";
import { default as changepassword5yNZka7H6rMeta } from "/vercel/path0/apps/main/pages/dashboard/account/changepassword.vue?macro=true";
import { default as editprofileGS4lx3avqiMeta } from "/vercel/path0/apps/main/pages/dashboard/account/editprofile.vue?macro=true";
import { default as indexDqLwLilSAmMeta } from "/vercel/path0/apps/main/pages/dashboard/account/index.vue?macro=true";
import { default as accountEhalYsnROyMeta } from "/vercel/path0/apps/main/pages/dashboard/account.vue?macro=true";
import { default as addZp8skxu2J8Meta } from "/vercel/path0/apps/main/pages/dashboard/billing/methods/add.vue?macro=true";
import { default as indexozvTZxoJRrMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/methods/index.vue?macro=true";
import { default as addhHzQGQrPdxMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue?macro=true";
import { default as removepqVX8icdGcMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue?macro=true";
import { default as addsubscription0gIAVnsWgUMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue?macro=true";
import { default as indexWxDgOYNohCMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue?macro=true";
import { default as index7mZcaPRdwLMeta } from "/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/index.vue?macro=true";
import { default as indexTHM0MiqjeOMeta } from "/vercel/path0/apps/main/pages/dashboard/index.vue?macro=true";
import { default as importGrGX5890kWMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue?macro=true";
import { default as indexHuQmqgGIVAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue?macro=true";
import { default as dimensionsCDQNiQXkxSMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions.vue?macro=true";
import { default as _91journalId_93W7i5aJisM0Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId].vue?macro=true";
import { default as indexO4HWn8H8QKMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue?macro=true";
import { default as customersUF3zhb5mGfMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers.vue?macro=true";
import { default as indexodMkvReCfsMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue?macro=true";
import { default as inventoryYK8wz8fS7pMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue?macro=true";
import { default as newCF8k1h3XX8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue?macro=true";
import { default as vendorsyb7olHCgjxMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors.vue?macro=true";
import { default as yeartoyearMhBJwPmFkdMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue?macro=true";
import { default as _91receiptId_93X3qAhqqDXlMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue?macro=true";
import { default as indexYvjYsQO6guMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue?macro=true";
import { default as settingsBWd8anrbb8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/settings.vue?macro=true";
import { default as _91assetId_93fRytpssviQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId].vue?macro=true";
import { default as indexo87uXVvfwAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue?macro=true";
import { default as settings9EPHMAU1iGMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/settings.vue?macro=true";
import { default as indexkqyW0Gj3KaMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/index.vue?macro=true";
import { default as categoriesXQVb4GPE8HMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories.vue?macro=true";
import { default as _91productId_93On6Oei9sjpMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId].vue?macro=true";
import { default as import1jHrIQPsObMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue?macro=true";
import { default as index8KIo3kzo9XMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue?macro=true";
import { default as settingsrL0nhkcFQiMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/settings.vue?macro=true";
import { default as _91stocktakeId_93y2GiOxvwSNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId].vue?macro=true";
import { default as indexFvqsAJesBgMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue?macro=true";
import { default as _91storeId_93hBJbUE8oETMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId].vue?macro=true";
import { default as indexuxw4HnIr1QMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue?macro=true";
import { default as _91transferId_93JfOQOHByZ8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue?macro=true";
import { default as indexwkU20oaki8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue?macro=true";
import { default as index51dwkgJLL6Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue?macro=true";
import { default as detailsR3Gh5ppEEDMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/details.vue?macro=true";
import { default as lockedperiodsVVazmEgMXUMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/lockedperiods.vue?macro=true";
import { default as membersq7Ht4lsbEeMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/members.vue?macro=true";
import { default as indexg5nfL1miGTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/index.vue?macro=true";
import { default as settingsuflVjndcXrMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/settings.vue?macro=true";
import { default as receiptpaymentmethodsS4wID02QW8Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/receiptpaymentmethods.vue?macro=true";
import { default as rolesr0mEw4PTHVMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/roles.vue?macro=true";
import { default as tax846tKj0iVQMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/tax.vue?macro=true";
import { default as generalreceipt_45_91id_93d2lkhzSlGhMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/generalreceipt-[id].vue?macro=true";
import { default as journal_45_91id_93HpnhlO5lqJMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/journal-[id].vue?macro=true";
import { default as stocktake_45_91id_939ISwGrDf6JMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/stocktake-[id].vue?macro=true";
import { default as transfer_45_91id_93nExM16aIqnMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/transfer-[id].vue?macro=true";
import { default as order_45_91id_93IdCXBgx4dxMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue?macro=true";
import { default as barcode_45_91id_9368jdQgMf4SMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue?macro=true";
import { default as debitnote_45_91id_93dBhtejwNSnMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/debitnote-[id].vue?macro=true";
import { default as purchaseorder_45_91id_93HVe1x9VXS5Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchaseorder-[id].vue?macro=true";
import { default as purchasinginvoice_45_91id_934xFLF3rVsXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasinginvoice-[id].vue?macro=true";
import { default as purchasingreceipt_45_91id_931bHVoJukTXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasingreceipt-[id].vue?macro=true";
import { default as accountstatementblZsZeVYCuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue?macro=true";
import { default as balancesheetac1Rka6AVHMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue?macro=true";
import { default as currentinventoryqAq4M18IeFMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue?macro=true";
import { default as customerstatement7dz4NH8NjZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue?macro=true";
import { default as incomestatementeILtsejdwmMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue?macro=true";
import { default as inventorystatementHjTD5QsJPZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventorystatement.vue?macro=true";
import { default as purchasinginvoices3PRiELbpqzMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue?macro=true";
import { default as salesinvoicesdDvZkTyFlDMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue?macro=true";
import { default as saudivatreportLGMNN83gonMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue?macro=true";
import { default as transactionshistoryEW23nFVN5nMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue?macro=true";
import { default as trialbalancex7hcyaJUpRMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue?macro=true";
import { default as vendorstatementUElEs4XxXNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue?macro=true";
import { default as creditnote_45_91id_93WLlvcYRT1rMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/creditnote-[id].vue?macro=true";
import { default as salesinvoice_45_91id_93AnQcrFGOJHMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesinvoice-[id].vue?macro=true";
import { default as salesquotation_45_91id_93m1ygKZbEsSMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesquotation-[id].vue?macro=true";
import { default as salesreceipt_45_91id_93erI0YycNOzMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesreceipt-[id].vue?macro=true";
import { default as printNOPSoVl9iIMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print.vue?macro=true";
import { default as _91debitNoteId_932DLT5x8JhqMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue?macro=true";
import { default as _91invoiceId_93Z3dh6a7XiPMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexg9OTBAwB9BMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue?macro=true";
import { default as _91invoiceId_93hCZ2UXzlhAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue?macro=true";
import { default as indexyp79PQ2JbLMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue?macro=true";
import { default as newt6tQAaU4sjMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue?macro=true";
import { default as purchaseorders2myjNbRtxTMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders.vue?macro=true";
import { default as _91receiptId_93kkirFhA1FcMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue?macro=true";
import { default as indexN0IXxidqBtMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue?macro=true";
import { default as settingsqhaXY6fQEAMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/settings.vue?macro=true";
import { default as _91vendorId_93GwKIb075LuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId].vue?macro=true";
import { default as importdiZqTH0b4GMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue?macro=true";
import { default as indexy6plzSnsBrMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue?macro=true";
import { default as accountstatementIaR84bwX49Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue?macro=true";
import { default as balancesheetRWxfF4brGuMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue?macro=true";
import { default as currentinventory3BgH2tuDJMMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue?macro=true";
import { default as customerstatementddjs0F3EokMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue?macro=true";
import { default as incomestatementUlDUQUpmiXMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue?macro=true";
import { default as inventorystatementQeTwHZN1LKMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/inventorystatement.vue?macro=true";
import { default as purchasinginvoicesTaEF3guwSwMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue?macro=true";
import { default as salesinvoicesbMMBSblExmMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue?macro=true";
import { default as saudivatreportKtn8iDPsn3Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport.vue?macro=true";
import { default as transactionshistoryFGdLeb4d7DMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue?macro=true";
import { default as trialbalance22Eg1ogX4RMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue?macro=true";
import { default as vendorstatementM2Ip9XLYNPMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue?macro=true";
import { default as _91creditNoteId_93jvEFpUEOroMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue?macro=true";
import { default as _91invoiceId_93kH2GXl0CxNMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue?macro=true";
import { default as indexk8t6WweXzbMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue?macro=true";
import { default as _91customerId_93DakJz6ZxWZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId].vue?macro=true";
import { default as importeQfTsNgBcpMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue?macro=true";
import { default as index5TpAK86Ye9Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue?macro=true";
import { default as _91invoiceId_93amfmQhqb2ZMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue?macro=true";
import { default as index8FQRdzpv5VMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue?macro=true";
import { default as newVUWhnepPdLMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue?macro=true";
import { default as _91receiptId_93U8CJX8pV5IMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue?macro=true";
import { default as indexo66vUAdk9DMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue?macro=true";
import { default as salesquotations3FiUZ681GeMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations.vue?macro=true";
import { default as settingsyxZg2xdbiwMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/settings.vue?macro=true";
import { default as _91orgId_93lGpo7w4kx6Meta } from "/vercel/path0/apps/main/pages/dashboard/organizations/[orgId].vue?macro=true";
import { default as createbiMY5gPZVOMeta } from "/vercel/path0/apps/main/pages/dashboard/organizations/create.vue?macro=true";
import { default as dashboardIzmeksiRZ4Meta } from "/vercel/path0/apps/main/pages/dashboard.vue?macro=true";
import { default as indexOyc1pbfuScMeta } from "/vercel/path0/apps/main/pages/index.vue?macro=true";
import { default as registerM10HqyFLuzMeta } from "/vercel/path0/apps/main/pages/register.vue?macro=true";
export default [
  {
    name: _91uuid_93ZrDXI0eeOCMeta?.name ?? "auth-email-verify-uuid",
    path: _91uuid_93ZrDXI0eeOCMeta?.path ?? "/auth/email/verify/:uuid()",
    meta: _91uuid_93ZrDXI0eeOCMeta || {},
    alias: _91uuid_93ZrDXI0eeOCMeta?.alias || [],
    redirect: _91uuid_93ZrDXI0eeOCMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/[uuid].vue").then(m => m.default || m)
  },
  {
    name: otpB7jAz4ETvCMeta?.name ?? "auth-email-verify-otp",
    path: otpB7jAz4ETvCMeta?.path ?? "/auth/email/verify/otp",
    meta: otpB7jAz4ETvCMeta || {},
    alias: otpB7jAz4ETvCMeta?.alias || [],
    redirect: otpB7jAz4ETvCMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/otp.vue").then(m => m.default || m)
  },
  {
    name: requestLIRS0QE8NqMeta?.name ?? "auth-email-verify-request",
    path: requestLIRS0QE8NqMeta?.path ?? "/auth/email/verify/request",
    meta: requestLIRS0QE8NqMeta || {},
    alias: requestLIRS0QE8NqMeta?.alias || [],
    redirect: requestLIRS0QE8NqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/email/verify/request.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93jRin05NlcQMeta?.name ?? "auth-invite-uuid",
    path: _91uuid_93jRin05NlcQMeta?.path ?? "/auth/invite/:uuid()",
    meta: _91uuid_93jRin05NlcQMeta || {},
    alias: _91uuid_93jRin05NlcQMeta?.alias || [],
    redirect: _91uuid_93jRin05NlcQMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/invite/[uuid].vue").then(m => m.default || m)
  },
  {
    name: indexmH2SdOBKhjMeta?.name ?? "auth-password-reset",
    path: indexmH2SdOBKhjMeta?.path ?? "/auth/password/reset",
    meta: indexmH2SdOBKhjMeta || {},
    alias: indexmH2SdOBKhjMeta?.alias || [],
    redirect: indexmH2SdOBKhjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: _91uuid_93qNnNEEpYZTMeta?.name ?? "auth-password-reset-submit-uuid",
    path: _91uuid_93qNnNEEpYZTMeta?.path ?? "/auth/password/reset/submit/:uuid()",
    meta: _91uuid_93qNnNEEpYZTMeta || {},
    alias: _91uuid_93qNnNEEpYZTMeta?.alias || [],
    redirect: _91uuid_93qNnNEEpYZTMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/auth/password/reset/submit/[uuid].vue").then(m => m.default || m)
  },
  {
    name: dashboardIzmeksiRZ4Meta?.name ?? undefined,
    path: dashboardIzmeksiRZ4Meta?.path ?? "/dashboard",
    meta: dashboardIzmeksiRZ4Meta || {},
    alias: dashboardIzmeksiRZ4Meta?.alias || [],
    redirect: dashboardIzmeksiRZ4Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard.vue").then(m => m.default || m),
    children: [
  {
    name: accountEhalYsnROyMeta?.name ?? undefined,
    path: accountEhalYsnROyMeta?.path ?? "account",
    meta: accountEhalYsnROyMeta || {},
    alias: accountEhalYsnROyMeta?.alias || [],
    redirect: accountEhalYsnROyMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account.vue").then(m => m.default || m),
    children: [
  {
    name: changeemailA1f58kT6GZMeta?.name ?? "dashboard-account-changeemail",
    path: changeemailA1f58kT6GZMeta?.path ?? "changeemail",
    meta: changeemailA1f58kT6GZMeta || {},
    alias: changeemailA1f58kT6GZMeta?.alias || [],
    redirect: changeemailA1f58kT6GZMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/changeemail.vue").then(m => m.default || m)
  },
  {
    name: changepassword5yNZka7H6rMeta?.name ?? "dashboard-account-changepassword",
    path: changepassword5yNZka7H6rMeta?.path ?? "changepassword",
    meta: changepassword5yNZka7H6rMeta || {},
    alias: changepassword5yNZka7H6rMeta?.alias || [],
    redirect: changepassword5yNZka7H6rMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/changepassword.vue").then(m => m.default || m)
  },
  {
    name: editprofileGS4lx3avqiMeta?.name ?? "dashboard-account-editprofile",
    path: editprofileGS4lx3avqiMeta?.path ?? "editprofile",
    meta: editprofileGS4lx3avqiMeta || {},
    alias: editprofileGS4lx3avqiMeta?.alias || [],
    redirect: editprofileGS4lx3avqiMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/editprofile.vue").then(m => m.default || m)
  },
  {
    name: indexDqLwLilSAmMeta?.name ?? "dashboard-account",
    path: indexDqLwLilSAmMeta?.path ?? "",
    meta: indexDqLwLilSAmMeta || {},
    alias: indexDqLwLilSAmMeta?.alias || [],
    redirect: indexDqLwLilSAmMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/account/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: addZp8skxu2J8Meta?.name ?? "dashboard-billing-methods-add",
    path: addZp8skxu2J8Meta?.path ?? "billing/methods/add",
    meta: addZp8skxu2J8Meta || {},
    alias: addZp8skxu2J8Meta?.alias || [],
    redirect: addZp8skxu2J8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/methods/add.vue").then(m => m.default || m)
  },
  {
    name: indexozvTZxoJRrMeta?.name ?? "dashboard-billing-methods",
    path: indexozvTZxoJRrMeta?.path ?? "billing/methods",
    meta: indexozvTZxoJRrMeta || {},
    alias: indexozvTZxoJRrMeta?.alias || [],
    redirect: indexozvTZxoJRrMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/methods/index.vue").then(m => m.default || m)
  },
  {
    name: addhHzQGQrPdxMeta?.name ?? "dashboard-billing-subscriptions-orgId-addons-add",
    path: addhHzQGQrPdxMeta?.path ?? "billing/subscriptions/:orgId()/addons/add",
    meta: addhHzQGQrPdxMeta || {},
    alias: addhHzQGQrPdxMeta?.alias || [],
    redirect: addhHzQGQrPdxMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/add.vue").then(m => m.default || m)
  },
  {
    name: removepqVX8icdGcMeta?.name ?? "dashboard-billing-subscriptions-orgId-addons-remove",
    path: removepqVX8icdGcMeta?.path ?? "billing/subscriptions/:orgId()/addons/remove",
    meta: removepqVX8icdGcMeta || {},
    alias: removepqVX8icdGcMeta?.alias || [],
    redirect: removepqVX8icdGcMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addons/remove.vue").then(m => m.default || m)
  },
  {
    name: addsubscription0gIAVnsWgUMeta?.name ?? "dashboard-billing-subscriptions-orgId-addsubscription",
    path: addsubscription0gIAVnsWgUMeta?.path ?? "billing/subscriptions/:orgId()/addsubscription",
    meta: addsubscription0gIAVnsWgUMeta || {},
    alias: addsubscription0gIAVnsWgUMeta?.alias || [],
    redirect: addsubscription0gIAVnsWgUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/addsubscription.vue").then(m => m.default || m)
  },
  {
    name: indexWxDgOYNohCMeta?.name ?? "dashboard-billing-subscriptions-orgId",
    path: indexWxDgOYNohCMeta?.path ?? "billing/subscriptions/:orgId()",
    meta: indexWxDgOYNohCMeta || {},
    alias: indexWxDgOYNohCMeta?.alias || [],
    redirect: indexWxDgOYNohCMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: index7mZcaPRdwLMeta?.name ?? "dashboard-billing-subscriptions",
    path: index7mZcaPRdwLMeta?.path ?? "billing/subscriptions",
    meta: index7mZcaPRdwLMeta || {},
    alias: index7mZcaPRdwLMeta?.alias || [],
    redirect: index7mZcaPRdwLMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/billing/subscriptions/index.vue").then(m => m.default || m)
  },
  {
    name: indexTHM0MiqjeOMeta?.name ?? "dashboard",
    path: indexTHM0MiqjeOMeta?.path ?? "",
    meta: indexTHM0MiqjeOMeta || {},
    alias: indexTHM0MiqjeOMeta?.alias || [],
    redirect: indexTHM0MiqjeOMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/index.vue").then(m => m.default || m)
  },
  {
    name: _91orgId_93lGpo7w4kx6Meta?.name ?? undefined,
    path: _91orgId_93lGpo7w4kx6Meta?.path ?? "organizations/:orgId()",
    meta: _91orgId_93lGpo7w4kx6Meta || {},
    alias: _91orgId_93lGpo7w4kx6Meta?.alias || [],
    redirect: _91orgId_93lGpo7w4kx6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId].vue").then(m => m.default || m),
    children: [
  {
    name: importGrGX5890kWMeta?.name ?? "dashboard-organizations-orgId-accounting-accounts-import",
    path: importGrGX5890kWMeta?.path ?? "accounting/accounts/import",
    meta: importGrGX5890kWMeta || {},
    alias: importGrGX5890kWMeta?.alias || [],
    redirect: importGrGX5890kWMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/import.vue").then(m => m.default || m)
  },
  {
    name: indexHuQmqgGIVAMeta?.name ?? "dashboard-organizations-orgId-accounting-accounts",
    path: indexHuQmqgGIVAMeta?.path ?? "accounting/accounts",
    meta: indexHuQmqgGIVAMeta || {},
    alias: indexHuQmqgGIVAMeta?.alias || [],
    redirect: indexHuQmqgGIVAMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/accounts/index.vue").then(m => m.default || m)
  },
  {
    name: dimensionsCDQNiQXkxSMeta?.name ?? "dashboard-organizations-orgId-accounting-dimensions",
    path: dimensionsCDQNiQXkxSMeta?.path ?? "accounting/dimensions",
    meta: dimensionsCDQNiQXkxSMeta || {},
    alias: dimensionsCDQNiQXkxSMeta?.alias || [],
    redirect: dimensionsCDQNiQXkxSMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/dimensions.vue").then(m => m.default || m)
  },
  {
    name: _91journalId_93W7i5aJisM0Meta?.name ?? "dashboard-organizations-orgId-accounting-journal-journalId",
    path: _91journalId_93W7i5aJisM0Meta?.path ?? "accounting/journal/:journalId()",
    meta: _91journalId_93W7i5aJisM0Meta || {},
    alias: _91journalId_93W7i5aJisM0Meta?.alias || [],
    redirect: _91journalId_93W7i5aJisM0Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/[journalId].vue").then(m => m.default || m)
  },
  {
    name: indexO4HWn8H8QKMeta?.name ?? "dashboard-organizations-orgId-accounting-journal",
    path: indexO4HWn8H8QKMeta?.path ?? "accounting/journal",
    meta: indexO4HWn8H8QKMeta || {},
    alias: indexO4HWn8H8QKMeta?.alias || [],
    redirect: indexO4HWn8H8QKMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/journal/index.vue").then(m => m.default || m)
  },
  {
    name: customersUF3zhb5mGfMeta?.name ?? "dashboard-organizations-orgId-accounting-opening-customers",
    path: customersUF3zhb5mGfMeta?.path ?? "accounting/opening/customers",
    meta: customersUF3zhb5mGfMeta || {},
    alias: customersUF3zhb5mGfMeta?.alias || [],
    redirect: customersUF3zhb5mGfMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/customers.vue").then(m => m.default || m)
  },
  {
    name: indexodMkvReCfsMeta?.name ?? "dashboard-organizations-orgId-accounting-opening",
    path: indexodMkvReCfsMeta?.path ?? "accounting/opening",
    meta: indexodMkvReCfsMeta || {},
    alias: indexodMkvReCfsMeta?.alias || [],
    redirect: indexodMkvReCfsMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/index.vue").then(m => m.default || m)
  },
  {
    name: inventoryYK8wz8fS7pMeta?.name ?? "dashboard-organizations-orgId-accounting-opening-inventory",
    path: inventoryYK8wz8fS7pMeta?.path ?? "accounting/opening/inventory",
    meta: inventoryYK8wz8fS7pMeta || {},
    alias: inventoryYK8wz8fS7pMeta?.alias || [],
    redirect: inventoryYK8wz8fS7pMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/inventory.vue").then(m => m.default || m)
  },
  {
    name: newCF8k1h3XX8Meta?.name ?? "dashboard-organizations-orgId-accounting-opening-new",
    path: newCF8k1h3XX8Meta?.path ?? "accounting/opening/new",
    meta: newCF8k1h3XX8Meta || {},
    alias: newCF8k1h3XX8Meta?.alias || [],
    redirect: newCF8k1h3XX8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/new.vue").then(m => m.default || m)
  },
  {
    name: vendorsyb7olHCgjxMeta?.name ?? "dashboard-organizations-orgId-accounting-opening-vendors",
    path: vendorsyb7olHCgjxMeta?.path ?? "accounting/opening/vendors",
    meta: vendorsyb7olHCgjxMeta || {},
    alias: vendorsyb7olHCgjxMeta?.alias || [],
    redirect: vendorsyb7olHCgjxMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/vendors.vue").then(m => m.default || m)
  },
  {
    name: yeartoyearMhBJwPmFkdMeta?.name ?? "dashboard-organizations-orgId-accounting-opening-yeartoyear",
    path: yeartoyearMhBJwPmFkdMeta?.path ?? "accounting/opening/yeartoyear",
    meta: yeartoyearMhBJwPmFkdMeta || {},
    alias: yeartoyearMhBJwPmFkdMeta?.alias || [],
    redirect: yeartoyearMhBJwPmFkdMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/opening/yeartoyear.vue").then(m => m.default || m)
  },
  {
    name: _91receiptId_93X3qAhqqDXlMeta?.name ?? "dashboard-organizations-orgId-accounting-receipts-receiptId",
    path: _91receiptId_93X3qAhqqDXlMeta?.path ?? "accounting/receipts/:receiptId()",
    meta: _91receiptId_93X3qAhqqDXlMeta || {},
    alias: _91receiptId_93X3qAhqqDXlMeta?.alias || [],
    redirect: _91receiptId_93X3qAhqqDXlMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: indexYvjYsQO6guMeta?.name ?? "dashboard-organizations-orgId-accounting-receipts",
    path: indexYvjYsQO6guMeta?.path ?? "accounting/receipts",
    meta: indexYvjYsQO6guMeta || {},
    alias: indexYvjYsQO6guMeta?.alias || [],
    redirect: indexYvjYsQO6guMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: settingsBWd8anrbb8Meta?.name ?? "dashboard-organizations-orgId-accounting-settings",
    path: settingsBWd8anrbb8Meta?.path ?? "accounting/settings",
    meta: settingsBWd8anrbb8Meta || {},
    alias: settingsBWd8anrbb8Meta?.alias || [],
    redirect: settingsBWd8anrbb8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/accounting/settings.vue").then(m => m.default || m)
  },
  {
    name: _91assetId_93fRytpssviQMeta?.name ?? "dashboard-organizations-orgId-fixedassets-assets-assetId",
    path: _91assetId_93fRytpssviQMeta?.path ?? "fixedassets/assets/:assetId()",
    meta: _91assetId_93fRytpssviQMeta || {},
    alias: _91assetId_93fRytpssviQMeta?.alias || [],
    redirect: _91assetId_93fRytpssviQMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/[assetId].vue").then(m => m.default || m)
  },
  {
    name: indexo87uXVvfwAMeta?.name ?? "dashboard-organizations-orgId-fixedassets-assets",
    path: indexo87uXVvfwAMeta?.path ?? "fixedassets/assets",
    meta: indexo87uXVvfwAMeta || {},
    alias: indexo87uXVvfwAMeta?.alias || [],
    redirect: indexo87uXVvfwAMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/assets/index.vue").then(m => m.default || m)
  },
  {
    name: settings9EPHMAU1iGMeta?.name ?? "dashboard-organizations-orgId-fixedassets-settings",
    path: settings9EPHMAU1iGMeta?.path ?? "fixedassets/settings",
    meta: settings9EPHMAU1iGMeta || {},
    alias: settings9EPHMAU1iGMeta?.alias || [],
    redirect: settings9EPHMAU1iGMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/fixedassets/settings.vue").then(m => m.default || m)
  },
  {
    name: indexkqyW0Gj3KaMeta?.name ?? "dashboard-organizations-orgId",
    path: indexkqyW0Gj3KaMeta?.path ?? "",
    meta: indexkqyW0Gj3KaMeta || {},
    alias: indexkqyW0Gj3KaMeta?.alias || [],
    redirect: indexkqyW0Gj3KaMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/index.vue").then(m => m.default || m)
  },
  {
    name: categoriesXQVb4GPE8HMeta?.name ?? "dashboard-organizations-orgId-inventory-categories",
    path: categoriesXQVb4GPE8HMeta?.path ?? "inventory/categories",
    meta: categoriesXQVb4GPE8HMeta || {},
    alias: categoriesXQVb4GPE8HMeta?.alias || [],
    redirect: categoriesXQVb4GPE8HMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/categories.vue").then(m => m.default || m)
  },
  {
    name: _91productId_93On6Oei9sjpMeta?.name ?? "dashboard-organizations-orgId-inventory-products-productId",
    path: _91productId_93On6Oei9sjpMeta?.path ?? "inventory/products/:productId()",
    meta: _91productId_93On6Oei9sjpMeta || {},
    alias: _91productId_93On6Oei9sjpMeta?.alias || [],
    redirect: _91productId_93On6Oei9sjpMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/[productId].vue").then(m => m.default || m)
  },
  {
    name: import1jHrIQPsObMeta?.name ?? "dashboard-organizations-orgId-inventory-products-import",
    path: import1jHrIQPsObMeta?.path ?? "inventory/products/import",
    meta: import1jHrIQPsObMeta || {},
    alias: import1jHrIQPsObMeta?.alias || [],
    redirect: import1jHrIQPsObMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/import.vue").then(m => m.default || m)
  },
  {
    name: index8KIo3kzo9XMeta?.name ?? "dashboard-organizations-orgId-inventory-products",
    path: index8KIo3kzo9XMeta?.path ?? "inventory/products",
    meta: index8KIo3kzo9XMeta || {},
    alias: index8KIo3kzo9XMeta?.alias || [],
    redirect: index8KIo3kzo9XMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/products/index.vue").then(m => m.default || m)
  },
  {
    name: settingsrL0nhkcFQiMeta?.name ?? "dashboard-organizations-orgId-inventory-settings",
    path: settingsrL0nhkcFQiMeta?.path ?? "inventory/settings",
    meta: settingsrL0nhkcFQiMeta || {},
    alias: settingsrL0nhkcFQiMeta?.alias || [],
    redirect: settingsrL0nhkcFQiMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/settings.vue").then(m => m.default || m)
  },
  {
    name: _91stocktakeId_93y2GiOxvwSNMeta?.name ?? "dashboard-organizations-orgId-inventory-stocktakes-stocktakeId",
    path: _91stocktakeId_93y2GiOxvwSNMeta?.path ?? "inventory/stocktakes/:stocktakeId()",
    meta: _91stocktakeId_93y2GiOxvwSNMeta || {},
    alias: _91stocktakeId_93y2GiOxvwSNMeta?.alias || [],
    redirect: _91stocktakeId_93y2GiOxvwSNMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/[stocktakeId].vue").then(m => m.default || m)
  },
  {
    name: indexFvqsAJesBgMeta?.name ?? "dashboard-organizations-orgId-inventory-stocktakes",
    path: indexFvqsAJesBgMeta?.path ?? "inventory/stocktakes",
    meta: indexFvqsAJesBgMeta || {},
    alias: indexFvqsAJesBgMeta?.alias || [],
    redirect: indexFvqsAJesBgMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stocktakes/index.vue").then(m => m.default || m)
  },
  {
    name: _91storeId_93hBJbUE8oETMeta?.name ?? "dashboard-organizations-orgId-inventory-stores-storeId",
    path: _91storeId_93hBJbUE8oETMeta?.path ?? "inventory/stores/:storeId()",
    meta: _91storeId_93hBJbUE8oETMeta || {},
    alias: _91storeId_93hBJbUE8oETMeta?.alias || [],
    redirect: _91storeId_93hBJbUE8oETMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/[storeId].vue").then(m => m.default || m)
  },
  {
    name: indexuxw4HnIr1QMeta?.name ?? "dashboard-organizations-orgId-inventory-stores",
    path: indexuxw4HnIr1QMeta?.path ?? "inventory/stores",
    meta: indexuxw4HnIr1QMeta || {},
    alias: indexuxw4HnIr1QMeta?.alias || [],
    redirect: indexuxw4HnIr1QMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/stores/index.vue").then(m => m.default || m)
  },
  {
    name: _91transferId_93JfOQOHByZ8Meta?.name ?? "dashboard-organizations-orgId-inventory-transfers-transferId",
    path: _91transferId_93JfOQOHByZ8Meta?.path ?? "inventory/transfers/:transferId()",
    meta: _91transferId_93JfOQOHByZ8Meta || {},
    alias: _91transferId_93JfOQOHByZ8Meta?.alias || [],
    redirect: _91transferId_93JfOQOHByZ8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/[transferId].vue").then(m => m.default || m)
  },
  {
    name: indexwkU20oaki8Meta?.name ?? "dashboard-organizations-orgId-inventory-transfers",
    path: indexwkU20oaki8Meta?.path ?? "inventory/transfers",
    meta: indexwkU20oaki8Meta || {},
    alias: indexwkU20oaki8Meta?.alias || [],
    redirect: indexwkU20oaki8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/inventory/transfers/index.vue").then(m => m.default || m)
  },
  {
    name: index51dwkgJLL6Meta?.name ?? "dashboard-organizations-orgId-onboarding",
    path: index51dwkgJLL6Meta?.path ?? "onboarding",
    meta: index51dwkgJLL6Meta || {},
    alias: index51dwkgJLL6Meta?.alias || [],
    redirect: index51dwkgJLL6Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/onboarding/index.vue").then(m => m.default || m)
  },
  {
    name: detailsR3Gh5ppEEDMeta?.name ?? "dashboard-organizations-orgId-org-details",
    path: detailsR3Gh5ppEEDMeta?.path ?? "org/details",
    meta: detailsR3Gh5ppEEDMeta || {},
    alias: detailsR3Gh5ppEEDMeta?.alias || [],
    redirect: detailsR3Gh5ppEEDMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/details.vue").then(m => m.default || m)
  },
  {
    name: lockedperiodsVVazmEgMXUMeta?.name ?? "dashboard-organizations-orgId-org-lockedperiods",
    path: lockedperiodsVVazmEgMXUMeta?.path ?? "org/lockedperiods",
    meta: lockedperiodsVVazmEgMXUMeta || {},
    alias: lockedperiodsVVazmEgMXUMeta?.alias || [],
    redirect: lockedperiodsVVazmEgMXUMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/lockedperiods.vue").then(m => m.default || m)
  },
  {
    name: membersq7Ht4lsbEeMeta?.name ?? "dashboard-organizations-orgId-org-members",
    path: membersq7Ht4lsbEeMeta?.path ?? "org/members",
    meta: membersq7Ht4lsbEeMeta || {},
    alias: membersq7Ht4lsbEeMeta?.alias || [],
    redirect: membersq7Ht4lsbEeMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/members.vue").then(m => m.default || m)
  },
  {
    name: indexg5nfL1miGTMeta?.name ?? "dashboard-organizations-orgId-org-pos",
    path: indexg5nfL1miGTMeta?.path ?? "org/pos",
    meta: indexg5nfL1miGTMeta || {},
    alias: indexg5nfL1miGTMeta?.alias || [],
    redirect: indexg5nfL1miGTMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/index.vue").then(m => m.default || m)
  },
  {
    name: settingsuflVjndcXrMeta?.name ?? "dashboard-organizations-orgId-org-pos-settings",
    path: settingsuflVjndcXrMeta?.path ?? "org/pos/settings",
    meta: settingsuflVjndcXrMeta || {},
    alias: settingsuflVjndcXrMeta?.alias || [],
    redirect: settingsuflVjndcXrMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/pos/settings.vue").then(m => m.default || m)
  },
  {
    name: receiptpaymentmethodsS4wID02QW8Meta?.name ?? "dashboard-organizations-orgId-org-receiptpaymentmethods",
    path: receiptpaymentmethodsS4wID02QW8Meta?.path ?? "org/receiptpaymentmethods",
    meta: receiptpaymentmethodsS4wID02QW8Meta || {},
    alias: receiptpaymentmethodsS4wID02QW8Meta?.alias || [],
    redirect: receiptpaymentmethodsS4wID02QW8Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/receiptpaymentmethods.vue").then(m => m.default || m)
  },
  {
    name: rolesr0mEw4PTHVMeta?.name ?? "dashboard-organizations-orgId-org-roles",
    path: rolesr0mEw4PTHVMeta?.path ?? "org/roles",
    meta: rolesr0mEw4PTHVMeta || {},
    alias: rolesr0mEw4PTHVMeta?.alias || [],
    redirect: rolesr0mEw4PTHVMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/roles.vue").then(m => m.default || m)
  },
  {
    name: tax846tKj0iVQMeta?.name ?? "dashboard-organizations-orgId-org-tax",
    path: tax846tKj0iVQMeta?.path ?? "org/tax",
    meta: tax846tKj0iVQMeta || {},
    alias: tax846tKj0iVQMeta?.alias || [],
    redirect: tax846tKj0iVQMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/org/tax.vue").then(m => m.default || m)
  },
  {
    name: printNOPSoVl9iIMeta?.name ?? "dashboard-organizations-orgId-print",
    path: printNOPSoVl9iIMeta?.path ?? "print",
    meta: printNOPSoVl9iIMeta || {},
    alias: printNOPSoVl9iIMeta?.alias || [],
    redirect: printNOPSoVl9iIMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print.vue").then(m => m.default || m),
    children: [
  {
    name: generalreceipt_45_91id_93d2lkhzSlGhMeta?.name ?? "dashboard-organizations-orgId-print-accounting-generalreceipt-id",
    path: generalreceipt_45_91id_93d2lkhzSlGhMeta?.path ?? "accounting/generalreceipt-:id()",
    meta: generalreceipt_45_91id_93d2lkhzSlGhMeta || {},
    alias: generalreceipt_45_91id_93d2lkhzSlGhMeta?.alias || [],
    redirect: generalreceipt_45_91id_93d2lkhzSlGhMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/generalreceipt-[id].vue").then(m => m.default || m)
  },
  {
    name: journal_45_91id_93HpnhlO5lqJMeta?.name ?? "dashboard-organizations-orgId-print-accounting-journal-id",
    path: journal_45_91id_93HpnhlO5lqJMeta?.path ?? "accounting/journal-:id()",
    meta: journal_45_91id_93HpnhlO5lqJMeta || {},
    alias: journal_45_91id_93HpnhlO5lqJMeta?.alias || [],
    redirect: journal_45_91id_93HpnhlO5lqJMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/accounting/journal-[id].vue").then(m => m.default || m)
  },
  {
    name: stocktake_45_91id_939ISwGrDf6JMeta?.name ?? "dashboard-organizations-orgId-print-inventory-stocktake-id",
    path: stocktake_45_91id_939ISwGrDf6JMeta?.path ?? "inventory/stocktake-:id()",
    meta: stocktake_45_91id_939ISwGrDf6JMeta || {},
    alias: stocktake_45_91id_939ISwGrDf6JMeta?.alias || [],
    redirect: stocktake_45_91id_939ISwGrDf6JMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/stocktake-[id].vue").then(m => m.default || m)
  },
  {
    name: transfer_45_91id_93nExM16aIqnMeta?.name ?? "dashboard-organizations-orgId-print-inventory-transfer-id",
    path: transfer_45_91id_93nExM16aIqnMeta?.path ?? "inventory/transfer-:id()",
    meta: transfer_45_91id_93nExM16aIqnMeta || {},
    alias: transfer_45_91id_93nExM16aIqnMeta?.alias || [],
    redirect: transfer_45_91id_93nExM16aIqnMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/inventory/transfer-[id].vue").then(m => m.default || m)
  },
  {
    name: order_45_91id_93IdCXBgx4dxMeta?.name ?? "dashboard-organizations-orgId-print-order-id",
    path: order_45_91id_93IdCXBgx4dxMeta?.path ?? "order-:id()",
    meta: order_45_91id_93IdCXBgx4dxMeta || {},
    alias: order_45_91id_93IdCXBgx4dxMeta?.alias || [],
    redirect: order_45_91id_93IdCXBgx4dxMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/order-[id].vue").then(m => m.default || m)
  },
  {
    name: barcode_45_91id_9368jdQgMf4SMeta?.name ?? "dashboard-organizations-orgId-print-products-barcode-id",
    path: barcode_45_91id_9368jdQgMf4SMeta?.path ?? "products/barcode-:id()",
    meta: barcode_45_91id_9368jdQgMf4SMeta || {},
    alias: barcode_45_91id_9368jdQgMf4SMeta?.alias || [],
    redirect: barcode_45_91id_9368jdQgMf4SMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/products/barcode-[id].vue").then(m => m.default || m)
  },
  {
    name: debitnote_45_91id_93dBhtejwNSnMeta?.name ?? "dashboard-organizations-orgId-print-purchasing-debitnote-id",
    path: debitnote_45_91id_93dBhtejwNSnMeta?.path ?? "purchasing/debitnote-:id()",
    meta: debitnote_45_91id_93dBhtejwNSnMeta || {},
    alias: debitnote_45_91id_93dBhtejwNSnMeta?.alias || [],
    redirect: debitnote_45_91id_93dBhtejwNSnMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/debitnote-[id].vue").then(m => m.default || m)
  },
  {
    name: purchaseorder_45_91id_93HVe1x9VXS5Meta?.name ?? "dashboard-organizations-orgId-print-purchasing-purchaseorder-id",
    path: purchaseorder_45_91id_93HVe1x9VXS5Meta?.path ?? "purchasing/purchaseorder-:id()",
    meta: purchaseorder_45_91id_93HVe1x9VXS5Meta || {},
    alias: purchaseorder_45_91id_93HVe1x9VXS5Meta?.alias || [],
    redirect: purchaseorder_45_91id_93HVe1x9VXS5Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchaseorder-[id].vue").then(m => m.default || m)
  },
  {
    name: purchasinginvoice_45_91id_934xFLF3rVsXMeta?.name ?? "dashboard-organizations-orgId-print-purchasing-purchasinginvoice-id",
    path: purchasinginvoice_45_91id_934xFLF3rVsXMeta?.path ?? "purchasing/purchasinginvoice-:id()",
    meta: purchasinginvoice_45_91id_934xFLF3rVsXMeta || {},
    alias: purchasinginvoice_45_91id_934xFLF3rVsXMeta?.alias || [],
    redirect: purchasinginvoice_45_91id_934xFLF3rVsXMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasinginvoice-[id].vue").then(m => m.default || m)
  },
  {
    name: purchasingreceipt_45_91id_931bHVoJukTXMeta?.name ?? "dashboard-organizations-orgId-print-purchasing-purchasingreceipt-id",
    path: purchasingreceipt_45_91id_931bHVoJukTXMeta?.path ?? "purchasing/purchasingreceipt-:id()",
    meta: purchasingreceipt_45_91id_931bHVoJukTXMeta || {},
    alias: purchasingreceipt_45_91id_931bHVoJukTXMeta?.alias || [],
    redirect: purchasingreceipt_45_91id_931bHVoJukTXMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/purchasing/purchasingreceipt-[id].vue").then(m => m.default || m)
  },
  {
    name: accountstatementblZsZeVYCuMeta?.name ?? "dashboard-organizations-orgId-print-reports-accountstatement",
    path: accountstatementblZsZeVYCuMeta?.path ?? "reports/accountstatement",
    meta: accountstatementblZsZeVYCuMeta || {},
    alias: accountstatementblZsZeVYCuMeta?.alias || [],
    redirect: accountstatementblZsZeVYCuMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: balancesheetac1Rka6AVHMeta?.name ?? "dashboard-organizations-orgId-print-reports-balancesheet",
    path: balancesheetac1Rka6AVHMeta?.path ?? "reports/balancesheet",
    meta: balancesheetac1Rka6AVHMeta || {},
    alias: balancesheetac1Rka6AVHMeta?.alias || [],
    redirect: balancesheetac1Rka6AVHMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: currentinventoryqAq4M18IeFMeta?.name ?? "dashboard-organizations-orgId-print-reports-currentinventory",
    path: currentinventoryqAq4M18IeFMeta?.path ?? "reports/currentinventory",
    meta: currentinventoryqAq4M18IeFMeta || {},
    alias: currentinventoryqAq4M18IeFMeta?.alias || [],
    redirect: currentinventoryqAq4M18IeFMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: customerstatement7dz4NH8NjZMeta?.name ?? "dashboard-organizations-orgId-print-reports-customerstatement",
    path: customerstatement7dz4NH8NjZMeta?.path ?? "reports/customerstatement",
    meta: customerstatement7dz4NH8NjZMeta || {},
    alias: customerstatement7dz4NH8NjZMeta?.alias || [],
    redirect: customerstatement7dz4NH8NjZMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: incomestatementeILtsejdwmMeta?.name ?? "dashboard-organizations-orgId-print-reports-incomestatement",
    path: incomestatementeILtsejdwmMeta?.path ?? "reports/incomestatement",
    meta: incomestatementeILtsejdwmMeta || {},
    alias: incomestatementeILtsejdwmMeta?.alias || [],
    redirect: incomestatementeILtsejdwmMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: inventorystatementHjTD5QsJPZMeta?.name ?? "dashboard-organizations-orgId-print-reports-inventorystatement",
    path: inventorystatementHjTD5QsJPZMeta?.path ?? "reports/inventorystatement",
    meta: inventorystatementHjTD5QsJPZMeta || {},
    alias: inventorystatementHjTD5QsJPZMeta?.alias || [],
    redirect: inventorystatementHjTD5QsJPZMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/inventorystatement.vue").then(m => m.default || m)
  },
  {
    name: purchasinginvoices3PRiELbpqzMeta?.name ?? "dashboard-organizations-orgId-print-reports-purchasinginvoices",
    path: purchasinginvoices3PRiELbpqzMeta?.path ?? "reports/purchasinginvoices",
    meta: purchasinginvoices3PRiELbpqzMeta || {},
    alias: purchasinginvoices3PRiELbpqzMeta?.alias || [],
    redirect: purchasinginvoices3PRiELbpqzMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: salesinvoicesdDvZkTyFlDMeta?.name ?? "dashboard-organizations-orgId-print-reports-salesinvoices",
    path: salesinvoicesdDvZkTyFlDMeta?.path ?? "reports/salesinvoices",
    meta: salesinvoicesdDvZkTyFlDMeta || {},
    alias: salesinvoicesdDvZkTyFlDMeta?.alias || [],
    redirect: salesinvoicesdDvZkTyFlDMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: saudivatreportLGMNN83gonMeta?.name ?? "dashboard-organizations-orgId-print-reports-saudivatreport",
    path: saudivatreportLGMNN83gonMeta?.path ?? "reports/saudivatreport",
    meta: saudivatreportLGMNN83gonMeta || {},
    alias: saudivatreportLGMNN83gonMeta?.alias || [],
    redirect: saudivatreportLGMNN83gonMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/saudivatreport.vue").then(m => m.default || m)
  },
  {
    name: transactionshistoryEW23nFVN5nMeta?.name ?? "dashboard-organizations-orgId-print-reports-transactionshistory",
    path: transactionshistoryEW23nFVN5nMeta?.path ?? "reports/transactionshistory",
    meta: transactionshistoryEW23nFVN5nMeta || {},
    alias: transactionshistoryEW23nFVN5nMeta?.alias || [],
    redirect: transactionshistoryEW23nFVN5nMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: trialbalancex7hcyaJUpRMeta?.name ?? "dashboard-organizations-orgId-print-reports-trialbalance",
    path: trialbalancex7hcyaJUpRMeta?.path ?? "reports/trialbalance",
    meta: trialbalancex7hcyaJUpRMeta || {},
    alias: trialbalancex7hcyaJUpRMeta?.alias || [],
    redirect: trialbalancex7hcyaJUpRMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: vendorstatementUElEs4XxXNMeta?.name ?? "dashboard-organizations-orgId-print-reports-vendorstatement",
    path: vendorstatementUElEs4XxXNMeta?.path ?? "reports/vendorstatement",
    meta: vendorstatementUElEs4XxXNMeta || {},
    alias: vendorstatementUElEs4XxXNMeta?.alias || [],
    redirect: vendorstatementUElEs4XxXNMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: creditnote_45_91id_93WLlvcYRT1rMeta?.name ?? "dashboard-organizations-orgId-print-sales-creditnote-id",
    path: creditnote_45_91id_93WLlvcYRT1rMeta?.path ?? "sales/creditnote-:id()",
    meta: creditnote_45_91id_93WLlvcYRT1rMeta || {},
    alias: creditnote_45_91id_93WLlvcYRT1rMeta?.alias || [],
    redirect: creditnote_45_91id_93WLlvcYRT1rMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/creditnote-[id].vue").then(m => m.default || m)
  },
  {
    name: salesinvoice_45_91id_93AnQcrFGOJHMeta?.name ?? "dashboard-organizations-orgId-print-sales-salesinvoice-id",
    path: salesinvoice_45_91id_93AnQcrFGOJHMeta?.path ?? "sales/salesinvoice-:id()",
    meta: salesinvoice_45_91id_93AnQcrFGOJHMeta || {},
    alias: salesinvoice_45_91id_93AnQcrFGOJHMeta?.alias || [],
    redirect: salesinvoice_45_91id_93AnQcrFGOJHMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesinvoice-[id].vue").then(m => m.default || m)
  },
  {
    name: salesquotation_45_91id_93m1ygKZbEsSMeta?.name ?? "dashboard-organizations-orgId-print-sales-salesquotation-id",
    path: salesquotation_45_91id_93m1ygKZbEsSMeta?.path ?? "sales/salesquotation-:id()",
    meta: salesquotation_45_91id_93m1ygKZbEsSMeta || {},
    alias: salesquotation_45_91id_93m1ygKZbEsSMeta?.alias || [],
    redirect: salesquotation_45_91id_93m1ygKZbEsSMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesquotation-[id].vue").then(m => m.default || m)
  },
  {
    name: salesreceipt_45_91id_93erI0YycNOzMeta?.name ?? "dashboard-organizations-orgId-print-sales-salesreceipt-id",
    path: salesreceipt_45_91id_93erI0YycNOzMeta?.path ?? "sales/salesreceipt-:id()",
    meta: salesreceipt_45_91id_93erI0YycNOzMeta || {},
    alias: salesreceipt_45_91id_93erI0YycNOzMeta?.alias || [],
    redirect: salesreceipt_45_91id_93erI0YycNOzMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/print/sales/salesreceipt-[id].vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91debitNoteId_932DLT5x8JhqMeta?.name ?? "dashboard-organizations-orgId-purchasing-debitnotes-debitNoteId",
    path: _91debitNoteId_932DLT5x8JhqMeta?.path ?? "purchasing/debitnotes/:debitNoteId()",
    meta: _91debitNoteId_932DLT5x8JhqMeta || {},
    alias: _91debitNoteId_932DLT5x8JhqMeta?.alias || [],
    redirect: _91debitNoteId_932DLT5x8JhqMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/[debitNoteId].vue").then(m => m.default || m)
  },
  {
    name: _91invoiceId_93Z3dh6a7XiPMeta?.name ?? "dashboard-organizations-orgId-purchasing-debitnotes-frominvoice-invoiceId",
    path: _91invoiceId_93Z3dh6a7XiPMeta?.path ?? "purchasing/debitnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93Z3dh6a7XiPMeta || {},
    alias: _91invoiceId_93Z3dh6a7XiPMeta?.alias || [],
    redirect: _91invoiceId_93Z3dh6a7XiPMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: indexg9OTBAwB9BMeta?.name ?? "dashboard-organizations-orgId-purchasing-debitnotes",
    path: indexg9OTBAwB9BMeta?.path ?? "purchasing/debitnotes",
    meta: indexg9OTBAwB9BMeta || {},
    alias: indexg9OTBAwB9BMeta?.alias || [],
    redirect: indexg9OTBAwB9BMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/debitnotes/index.vue").then(m => m.default || m)
  },
  {
    name: _91invoiceId_93hCZ2UXzlhAMeta?.name ?? "dashboard-organizations-orgId-purchasing-invoices-invoiceId",
    path: _91invoiceId_93hCZ2UXzlhAMeta?.path ?? "purchasing/invoices/:invoiceId()",
    meta: _91invoiceId_93hCZ2UXzlhAMeta || {},
    alias: _91invoiceId_93hCZ2UXzlhAMeta?.alias || [],
    redirect: _91invoiceId_93hCZ2UXzlhAMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: indexyp79PQ2JbLMeta?.name ?? "dashboard-organizations-orgId-purchasing-invoices",
    path: indexyp79PQ2JbLMeta?.path ?? "purchasing/invoices",
    meta: indexyp79PQ2JbLMeta || {},
    alias: indexyp79PQ2JbLMeta?.alias || [],
    redirect: indexyp79PQ2JbLMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: newt6tQAaU4sjMeta?.name ?? "dashboard-organizations-orgId-purchasing-invoices-new",
    path: newt6tQAaU4sjMeta?.path ?? "purchasing/invoices/new",
    meta: newt6tQAaU4sjMeta || {},
    alias: newt6tQAaU4sjMeta?.alias || [],
    redirect: newt6tQAaU4sjMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: purchaseorders2myjNbRtxTMeta?.name ?? "dashboard-organizations-orgId-purchasing-purchaseorders",
    path: purchaseorders2myjNbRtxTMeta?.path ?? "purchasing/purchaseorders",
    meta: purchaseorders2myjNbRtxTMeta || {},
    alias: purchaseorders2myjNbRtxTMeta?.alias || [],
    redirect: purchaseorders2myjNbRtxTMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/purchaseorders.vue").then(m => m.default || m)
  },
  {
    name: _91receiptId_93kkirFhA1FcMeta?.name ?? "dashboard-organizations-orgId-purchasing-receipts-receiptId",
    path: _91receiptId_93kkirFhA1FcMeta?.path ?? "purchasing/receipts/:receiptId()",
    meta: _91receiptId_93kkirFhA1FcMeta || {},
    alias: _91receiptId_93kkirFhA1FcMeta?.alias || [],
    redirect: _91receiptId_93kkirFhA1FcMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: indexN0IXxidqBtMeta?.name ?? "dashboard-organizations-orgId-purchasing-receipts",
    path: indexN0IXxidqBtMeta?.path ?? "purchasing/receipts",
    meta: indexN0IXxidqBtMeta || {},
    alias: indexN0IXxidqBtMeta?.alias || [],
    redirect: indexN0IXxidqBtMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: settingsqhaXY6fQEAMeta?.name ?? "dashboard-organizations-orgId-purchasing-settings",
    path: settingsqhaXY6fQEAMeta?.path ?? "purchasing/settings",
    meta: settingsqhaXY6fQEAMeta || {},
    alias: settingsqhaXY6fQEAMeta?.alias || [],
    redirect: settingsqhaXY6fQEAMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/settings.vue").then(m => m.default || m)
  },
  {
    name: _91vendorId_93GwKIb075LuMeta?.name ?? "dashboard-organizations-orgId-purchasing-vendors-vendorId",
    path: _91vendorId_93GwKIb075LuMeta?.path ?? "purchasing/vendors/:vendorId()",
    meta: _91vendorId_93GwKIb075LuMeta || {},
    alias: _91vendorId_93GwKIb075LuMeta?.alias || [],
    redirect: _91vendorId_93GwKIb075LuMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/[vendorId].vue").then(m => m.default || m)
  },
  {
    name: importdiZqTH0b4GMeta?.name ?? "dashboard-organizations-orgId-purchasing-vendors-import",
    path: importdiZqTH0b4GMeta?.path ?? "purchasing/vendors/import",
    meta: importdiZqTH0b4GMeta || {},
    alias: importdiZqTH0b4GMeta?.alias || [],
    redirect: importdiZqTH0b4GMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/import.vue").then(m => m.default || m)
  },
  {
    name: indexy6plzSnsBrMeta?.name ?? "dashboard-organizations-orgId-purchasing-vendors",
    path: indexy6plzSnsBrMeta?.path ?? "purchasing/vendors",
    meta: indexy6plzSnsBrMeta || {},
    alias: indexy6plzSnsBrMeta?.alias || [],
    redirect: indexy6plzSnsBrMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/purchasing/vendors/index.vue").then(m => m.default || m)
  },
  {
    name: accountstatementIaR84bwX49Meta?.name ?? "dashboard-organizations-orgId-reports-accountstatement",
    path: accountstatementIaR84bwX49Meta?.path ?? "reports/accountstatement",
    meta: accountstatementIaR84bwX49Meta || {},
    alias: accountstatementIaR84bwX49Meta?.alias || [],
    redirect: accountstatementIaR84bwX49Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/accountstatement.vue").then(m => m.default || m)
  },
  {
    name: balancesheetRWxfF4brGuMeta?.name ?? "dashboard-organizations-orgId-reports-balancesheet",
    path: balancesheetRWxfF4brGuMeta?.path ?? "reports/balancesheet",
    meta: balancesheetRWxfF4brGuMeta || {},
    alias: balancesheetRWxfF4brGuMeta?.alias || [],
    redirect: balancesheetRWxfF4brGuMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/balancesheet.vue").then(m => m.default || m)
  },
  {
    name: currentinventory3BgH2tuDJMMeta?.name ?? "dashboard-organizations-orgId-reports-currentinventory",
    path: currentinventory3BgH2tuDJMMeta?.path ?? "reports/currentinventory",
    meta: currentinventory3BgH2tuDJMMeta || {},
    alias: currentinventory3BgH2tuDJMMeta?.alias || [],
    redirect: currentinventory3BgH2tuDJMMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/currentinventory.vue").then(m => m.default || m)
  },
  {
    name: customerstatementddjs0F3EokMeta?.name ?? "dashboard-organizations-orgId-reports-customerstatement",
    path: customerstatementddjs0F3EokMeta?.path ?? "reports/customerstatement",
    meta: customerstatementddjs0F3EokMeta || {},
    alias: customerstatementddjs0F3EokMeta?.alias || [],
    redirect: customerstatementddjs0F3EokMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/customerstatement.vue").then(m => m.default || m)
  },
  {
    name: incomestatementUlDUQUpmiXMeta?.name ?? "dashboard-organizations-orgId-reports-incomestatement",
    path: incomestatementUlDUQUpmiXMeta?.path ?? "reports/incomestatement",
    meta: incomestatementUlDUQUpmiXMeta || {},
    alias: incomestatementUlDUQUpmiXMeta?.alias || [],
    redirect: incomestatementUlDUQUpmiXMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/incomestatement.vue").then(m => m.default || m)
  },
  {
    name: inventorystatementQeTwHZN1LKMeta?.name ?? "dashboard-organizations-orgId-reports-inventorystatement",
    path: inventorystatementQeTwHZN1LKMeta?.path ?? "reports/inventorystatement",
    meta: inventorystatementQeTwHZN1LKMeta || {},
    alias: inventorystatementQeTwHZN1LKMeta?.alias || [],
    redirect: inventorystatementQeTwHZN1LKMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/inventorystatement.vue").then(m => m.default || m)
  },
  {
    name: purchasinginvoicesTaEF3guwSwMeta?.name ?? "dashboard-organizations-orgId-reports-purchasinginvoices",
    path: purchasinginvoicesTaEF3guwSwMeta?.path ?? "reports/purchasinginvoices",
    meta: purchasinginvoicesTaEF3guwSwMeta || {},
    alias: purchasinginvoicesTaEF3guwSwMeta?.alias || [],
    redirect: purchasinginvoicesTaEF3guwSwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/purchasinginvoices.vue").then(m => m.default || m)
  },
  {
    name: salesinvoicesbMMBSblExmMeta?.name ?? "dashboard-organizations-orgId-reports-salesinvoices",
    path: salesinvoicesbMMBSblExmMeta?.path ?? "reports/salesinvoices",
    meta: salesinvoicesbMMBSblExmMeta || {},
    alias: salesinvoicesbMMBSblExmMeta?.alias || [],
    redirect: salesinvoicesbMMBSblExmMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/salesinvoices.vue").then(m => m.default || m)
  },
  {
    name: saudivatreportKtn8iDPsn3Meta?.name ?? "dashboard-organizations-orgId-reports-saudivatreport",
    path: saudivatreportKtn8iDPsn3Meta?.path ?? "reports/saudivatreport",
    meta: saudivatreportKtn8iDPsn3Meta || {},
    alias: saudivatreportKtn8iDPsn3Meta?.alias || [],
    redirect: saudivatreportKtn8iDPsn3Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/saudivatreport.vue").then(m => m.default || m)
  },
  {
    name: transactionshistoryFGdLeb4d7DMeta?.name ?? "dashboard-organizations-orgId-reports-transactionshistory",
    path: transactionshistoryFGdLeb4d7DMeta?.path ?? "reports/transactionshistory",
    meta: transactionshistoryFGdLeb4d7DMeta || {},
    alias: transactionshistoryFGdLeb4d7DMeta?.alias || [],
    redirect: transactionshistoryFGdLeb4d7DMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/transactionshistory.vue").then(m => m.default || m)
  },
  {
    name: trialbalance22Eg1ogX4RMeta?.name ?? "dashboard-organizations-orgId-reports-trialbalance",
    path: trialbalance22Eg1ogX4RMeta?.path ?? "reports/trialbalance",
    meta: trialbalance22Eg1ogX4RMeta || {},
    alias: trialbalance22Eg1ogX4RMeta?.alias || [],
    redirect: trialbalance22Eg1ogX4RMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/trialbalance.vue").then(m => m.default || m)
  },
  {
    name: vendorstatementM2Ip9XLYNPMeta?.name ?? "dashboard-organizations-orgId-reports-vendorstatement",
    path: vendorstatementM2Ip9XLYNPMeta?.path ?? "reports/vendorstatement",
    meta: vendorstatementM2Ip9XLYNPMeta || {},
    alias: vendorstatementM2Ip9XLYNPMeta?.alias || [],
    redirect: vendorstatementM2Ip9XLYNPMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/reports/vendorstatement.vue").then(m => m.default || m)
  },
  {
    name: _91creditNoteId_93jvEFpUEOroMeta?.name ?? "dashboard-organizations-orgId-sales-creditnotes-creditNoteId",
    path: _91creditNoteId_93jvEFpUEOroMeta?.path ?? "sales/creditnotes/:creditNoteId()",
    meta: _91creditNoteId_93jvEFpUEOroMeta || {},
    alias: _91creditNoteId_93jvEFpUEOroMeta?.alias || [],
    redirect: _91creditNoteId_93jvEFpUEOroMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/[creditNoteId].vue").then(m => m.default || m)
  },
  {
    name: _91invoiceId_93kH2GXl0CxNMeta?.name ?? "dashboard-organizations-orgId-sales-creditnotes-frominvoice-invoiceId",
    path: _91invoiceId_93kH2GXl0CxNMeta?.path ?? "sales/creditnotes/frominvoice/:invoiceId()",
    meta: _91invoiceId_93kH2GXl0CxNMeta || {},
    alias: _91invoiceId_93kH2GXl0CxNMeta?.alias || [],
    redirect: _91invoiceId_93kH2GXl0CxNMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/frominvoice/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: indexk8t6WweXzbMeta?.name ?? "dashboard-organizations-orgId-sales-creditnotes",
    path: indexk8t6WweXzbMeta?.path ?? "sales/creditnotes",
    meta: indexk8t6WweXzbMeta || {},
    alias: indexk8t6WweXzbMeta?.alias || [],
    redirect: indexk8t6WweXzbMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/creditnotes/index.vue").then(m => m.default || m)
  },
  {
    name: _91customerId_93DakJz6ZxWZMeta?.name ?? "dashboard-organizations-orgId-sales-customers-customerId",
    path: _91customerId_93DakJz6ZxWZMeta?.path ?? "sales/customers/:customerId()",
    meta: _91customerId_93DakJz6ZxWZMeta || {},
    alias: _91customerId_93DakJz6ZxWZMeta?.alias || [],
    redirect: _91customerId_93DakJz6ZxWZMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/[customerId].vue").then(m => m.default || m)
  },
  {
    name: importeQfTsNgBcpMeta?.name ?? "dashboard-organizations-orgId-sales-customers-import",
    path: importeQfTsNgBcpMeta?.path ?? "sales/customers/import",
    meta: importeQfTsNgBcpMeta || {},
    alias: importeQfTsNgBcpMeta?.alias || [],
    redirect: importeQfTsNgBcpMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/import.vue").then(m => m.default || m)
  },
  {
    name: index5TpAK86Ye9Meta?.name ?? "dashboard-organizations-orgId-sales-customers",
    path: index5TpAK86Ye9Meta?.path ?? "sales/customers",
    meta: index5TpAK86Ye9Meta || {},
    alias: index5TpAK86Ye9Meta?.alias || [],
    redirect: index5TpAK86Ye9Meta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/customers/index.vue").then(m => m.default || m)
  },
  {
    name: _91invoiceId_93amfmQhqb2ZMeta?.name ?? "dashboard-organizations-orgId-sales-invoices-invoiceId",
    path: _91invoiceId_93amfmQhqb2ZMeta?.path ?? "sales/invoices/:invoiceId()",
    meta: _91invoiceId_93amfmQhqb2ZMeta || {},
    alias: _91invoiceId_93amfmQhqb2ZMeta?.alias || [],
    redirect: _91invoiceId_93amfmQhqb2ZMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/[invoiceId].vue").then(m => m.default || m)
  },
  {
    name: index8FQRdzpv5VMeta?.name ?? "dashboard-organizations-orgId-sales-invoices",
    path: index8FQRdzpv5VMeta?.path ?? "sales/invoices",
    meta: index8FQRdzpv5VMeta || {},
    alias: index8FQRdzpv5VMeta?.alias || [],
    redirect: index8FQRdzpv5VMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/index.vue").then(m => m.default || m)
  },
  {
    name: newVUWhnepPdLMeta?.name ?? "dashboard-organizations-orgId-sales-invoices-new",
    path: newVUWhnepPdLMeta?.path ?? "sales/invoices/new",
    meta: newVUWhnepPdLMeta || {},
    alias: newVUWhnepPdLMeta?.alias || [],
    redirect: newVUWhnepPdLMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/invoices/new.vue").then(m => m.default || m)
  },
  {
    name: _91receiptId_93U8CJX8pV5IMeta?.name ?? "dashboard-organizations-orgId-sales-receipts-receiptId",
    path: _91receiptId_93U8CJX8pV5IMeta?.path ?? "sales/receipts/:receiptId()",
    meta: _91receiptId_93U8CJX8pV5IMeta || {},
    alias: _91receiptId_93U8CJX8pV5IMeta?.alias || [],
    redirect: _91receiptId_93U8CJX8pV5IMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/[receiptId].vue").then(m => m.default || m)
  },
  {
    name: indexo66vUAdk9DMeta?.name ?? "dashboard-organizations-orgId-sales-receipts",
    path: indexo66vUAdk9DMeta?.path ?? "sales/receipts",
    meta: indexo66vUAdk9DMeta || {},
    alias: indexo66vUAdk9DMeta?.alias || [],
    redirect: indexo66vUAdk9DMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/receipts/index.vue").then(m => m.default || m)
  },
  {
    name: salesquotations3FiUZ681GeMeta?.name ?? "dashboard-organizations-orgId-sales-salesquotations",
    path: salesquotations3FiUZ681GeMeta?.path ?? "sales/salesquotations",
    meta: salesquotations3FiUZ681GeMeta || {},
    alias: salesquotations3FiUZ681GeMeta?.alias || [],
    redirect: salesquotations3FiUZ681GeMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/salesquotations.vue").then(m => m.default || m)
  },
  {
    name: settingsyxZg2xdbiwMeta?.name ?? "dashboard-organizations-orgId-sales-settings",
    path: settingsyxZg2xdbiwMeta?.path ?? "sales/settings",
    meta: settingsyxZg2xdbiwMeta || {},
    alias: settingsyxZg2xdbiwMeta?.alias || [],
    redirect: settingsyxZg2xdbiwMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/[orgId]/sales/settings.vue").then(m => m.default || m)
  }
]
  },
  {
    name: createbiMY5gPZVOMeta?.name ?? "dashboard-organizations-create",
    path: createbiMY5gPZVOMeta?.path ?? "organizations/create",
    meta: createbiMY5gPZVOMeta || {},
    alias: createbiMY5gPZVOMeta?.alias || [],
    redirect: createbiMY5gPZVOMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/dashboard/organizations/create.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexOyc1pbfuScMeta?.name ?? "index",
    path: indexOyc1pbfuScMeta?.path ?? "/",
    meta: indexOyc1pbfuScMeta || {},
    alias: indexOyc1pbfuScMeta?.alias || [],
    redirect: indexOyc1pbfuScMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/index.vue").then(m => m.default || m)
  },
  {
    name: registerM10HqyFLuzMeta?.name ?? "register",
    path: registerM10HqyFLuzMeta?.path ?? "/register",
    meta: registerM10HqyFLuzMeta || {},
    alias: registerM10HqyFLuzMeta?.alias || [],
    redirect: registerM10HqyFLuzMeta?.redirect,
    component: () => import("/vercel/path0/apps/main/pages/register.vue").then(m => m.default || m)
  }
]