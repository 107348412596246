export const organizationDetailsQuery = graphql(/* GraphQL */ `
  query OrganizationDetails($id: uuid!, $currentDate: date!) {
    organization: organizations_by_pk(id: $id) {
      ownerId
      isActive
      name
      currency
      countryId
      crNumber
      taxNumber
      website
      logoUrl
      enLogoUrl
      arLogoUrl
      posLogoUrl
      phone
      email
      displayName
      displayNameTranslations
      address
      addressTranslations
      createdAt
      updatedAt
      owner {
        fullName
      }
      organizationLogosUrls {
        logoUrl
        enLogoUrl
        arLogoUrl
        posLogoUrl
      }
      subscriptionsHistories(
        where: {
          cancellationDate: { _is_null: true }
          startDate: { _lte: $currentDate }
          expiryDate: { _gte: $currentDate }
        }
        order_by: { expiryDate: desc }
      ) {
        plan {
          enableFixedAssetsModule
          enableInventoryTracking
          enableManualJournals
          enableReportingDimensions
        }
      }
    }
  }
`);

export const organizationAccountsQuery = graphql(/* GraphQL */ `
  query OrganizationAccounts($orgId: uuid!) {
    accounts(
      where: { orgId: { _eq: $orgId } }
      order_by: [
        { type: asc }
        { codeLevel1: asc }
        { codeLevel2: asc }
        { codeLevel3: asc }
        { codeLevel4: asc }
        { codeLevel5: asc }
        { codeLevel6: asc }
        { codeLevel7: asc }
      ]
    ) {
      id
      orgId
      type
      isActive
      category
      name
      nameTranslations
      description
      descriptionTranslations
      codeLevel1
      codeLevel2
      codeLevel3
      codeLevel4
      codeLevel5
      codeLevel6
      codeLevel7
      createdAt
      updatedAt
    }
  }
`);

export const organizationMembersQuery = graphql(/* GraphQL */ `
  query OrganizationMembers($orgId: uuid!) {
    organizationMembers(
      where: { orgId: { _eq: $orgId }, acceptedAt: { _is_null: false } }
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      updatedAt
      deletedAt
      acceptedAt
      orgId
      name
      userId
      inviteEmail
      notes
      isManager
      isSuspended
      isReadonly
      organizationMemberRoles {
        roleId
        memberId
        organizationRole {
          id
          name
          description
          accessRules
        }
      }
    }
  }
`);

export const organizationReceiptPaymentMethodsQuery = graphql(/* GraphQL */ `
  query OrganizationReceiptPaymentMethods($orgId: uuid!) {
    receiptPaymentMethods(
      where: { orgId: { _eq: $orgId } }
      order_by: { createdAt: asc }
    ) {
      id
      createdAt
      updatedAt
      name
      nameTranslations
      displayName
      displayNameTranslations
      description
      descriptionTranslations
      isEnabled
      isPosEnabled
      receiptType
      hasCost
      costFixed
      costName
      costNameTranslations
      costPercent
      expenseAccountId
      cashAccountId
      taxId
      taxType {
        id
        accountId
        name
        nameTranslations
        displayName
        displayNameTranslations
        taxPercent
        isDeductible
      }
    }
  }
`);

export const reportingDimensionsQuery = graphql(/* GraphQL */ `
  query ReportingDimensions($orgId: uuid!) {
    reportingDimensions(where: { orgId: { _eq: $orgId } }) {
      id
      createdAt
      updatedAt
      name
      nameTranslations
      description
      descriptionTranslations
      isArchived
    }
  }
`);

export const organizationLockedPeriodsQuery = graphql(/* GraphQL */ `
  query OrganizationLockedPeriods($orgId: uuid!) {
    lockedPeriods(where: { orgId: { _eq: $orgId } }) {
      id
      createdAt
      orgId
      startDate
      endDate
      isActive
    }
  }
`);

export const organizationSettingsQuery = graphql(/* GraphQL */ `
  query OrganizationSettings($orgId: uuid!) {
    salesSettings: salesSettings_by_pk(orgId: $orgId) {
      defaultCashAccountId
      defaultReceivablesAccountId
      invoicesPrintNotesDefault
      invoicesReferenceCounter
      invoicesReferenceIsYearPrefixed
      invoicesReferencePrefix
      receiptsReferenceCounter
      receiptsReferenceIsYearPrefixed
      receiptsReferencePrefix
      salesQuotationsReferenceCounter
      salesQuotationsReferenceIsYearPrefixed
      salesQuotationsReferencePrefix
      creditNotesReferenceCounter
      creditNotesReferenceIsYearPrefixed
      creditNotesReferencePrefix
      defaultIsCash
      updatedAt
      enableInvoicePaymentMethods
    }
    purchasingSettings: purchasingSettings_by_pk(orgId: $orgId) {
      defaultCashAccountId
      defaultPayablesAccountId
      invoicesReferenceCounter
      invoicesReferenceIsYearPrefixed
      invoicesReferencePrefix
      receiptsReferenceCounter
      receiptsReferenceIsYearPrefixed
      receiptsReferencePrefix
      purchaseOrdersReferenceCounter
      purchaseOrdersReferenceIsYearPrefixed
      purchaseOrdersReferencePrefix
      debitNotesReferenceCounter
      debitNotesReferenceIsYearPrefixed
      debitNotesReferencePrefix
      updatedAt
      enableInvoicePaymentMethods
    }
    productsSettings: productsSettings_by_pk(orgId: $orgId) {
      updatedAt
      defaultCategoryId
      defaultCogsAccountId
      defaultExpenseAccountId
      defaultRevenueAccountId
      defaultStocktakeExpenseAccountId
      defaultStocktakeRevenueAccountId
      defaultStoreId
      singleStoreMode
      stocktakesReferenceCounter
      stocktakesReferenceIsYearPrefixed
      stocktakesReferencePrefix
      transfersReferenceCounter
      transfersReferenceIsYearPrefixed
      transfersReferencePrefix
      defaultTaxIsIncluded
    }
    fixedAssetsSettings: fixedAssetsSettings_by_pk(orgId: $orgId) {
      updatedAt
      defaultAppreciationAccountId
      defaultDepreciationAccountId
      defaultAssetsAcquisitionAccountId
      defaultDepreciationLifetime
    }
    transactionsSettings: transactionsSettings_by_pk(orgId: $orgId) {
      updatedAt
      backDatingRange
      forwardDatingRange
      isConfirmedLock
      openingBalanceAccountId
      journalsReferencePrefix
      journalsReferenceCounter
      journalsReferenceIsYearPrefixed
      receiptsReferencePrefix
      receiptsReferenceCounter
      receiptsReferenceIsYearPrefixed
      financialYearOffset
    }
  }
`);

export const organizationsQuery = graphql(/* GraphQL */ `
  query Organizations($currentDate: date!) {
    organizations(
      order_by: [{ isActive: desc_nulls_last }, { name: asc_nulls_last }]
    ) {
      id
      ownerId
      name
      currency
      logoUrl
      isActive
      isArchived
      createdAt
      updatedAt
      subscriptionsHistories(
        where: {
          cancellationDate: { _is_null: true }
          startDate: { _lte: $currentDate }
          expiryDate: { _gte: $currentDate }
        }
        order_by: { expiryDate: desc }
        limit: 1
      ) {
        id
        expiryDate
        isTrial
        plan {
          name
          details
        }
      }
      organizationACL {
        isOwner
        isManager
        memberRoles
      }
      organizationLogosUrls {
        logoUrl
      }
    }
  }
`);
