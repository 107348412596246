import validate from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.60.4_floating-vue@5._3z73ojvx34a4oefa7xms55pwt4/node_modules/nuxt/dist/pages/runtime/validate.js";
import auth_45global from "/vercel/path0/apps/main/middleware/auth.global.ts";
import authorize_45global from "/vercel/path0/apps/main/middleware/authorize.global.ts";
import manifest_45route_45rule from "/vercel/path0/node_modules/.pnpm/nuxt@3.11.2_@opentelemetry+api@1.8.0_@types+node@20.14.2_@unocss+reset@0.60.4_floating-vue@5._3z73ojvx34a4oefa7xms55pwt4/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  auth_45global,
  authorize_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {}